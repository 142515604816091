//const frontendUrl = "http://127.0.0.1:8080";
//const imgurl = "http://127.0.0.1:8100/"
//const webSite = "http://localhost:8000"

const frontendUrl = "https://www.xxatis.com/service/";
const imgurl = "https://gld.xxatis.com/"
const webSite = "https://www.xxatis.com/"
window["frontendUrl"] = frontendUrl;
export default {
    frontendUrl,
    imgurl,
    webSite
}
