import {createRouter, createWebHashHistory} from 'vue-router';

const childrenRouter = [
    {
        path: '/adminUserList',
        name: 'adminUserList',
        component: () => import('./views/modules/adminUser/adminUserList')
    },
    {
        path: '/backgroundIndex',
        name: 'dashboard',
        component: () => import('./views/modules/Dashboard'),
    },
    {
        path: '/appeal',
        name: 'appeal',
        component: () => import('./views/modules/Appeal'),
    },
    {
        path: '/certification',
        name: 'certification',
        component: () => import('./views/modules/Certification'),
    },
    {
        path: '/message',
        name: 'message',
        component: () => import('./views/modules/Message'),
    },
    {
        path: '/messageDetail',
        name: 'messageDetail',
        component: () => import('./views/modules/MessageDetail'),
    },
    {
        path: '/recruit/recruitList',
        name: 'recruitList',
        component: () => import('./views/modules/recruit/RecruitList')
    },
    {
        path: '/recruit/carouselChart',
        name: 'carouselChart',
        component: () => import('./views/modules/recruit/CarouselChart')
    },
    {
        path: '/recruit/blacklist',
        name: 'blacklist',
        component: () => import('./views/modules/recruit/blacklist')
    },
    {
        path: '/recruit/blackListDetail',
        name: 'blackListDetail',
        component: () => import('./views/modules/recruit/blackListDetail')
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('./views/modules/Events')
    },
    {
        path: '/eventDetail',
        name: 'eventDetail',
        component: () => import('./views/modules/EventDetail')
    },
    {
        path: '/businessRequirements/RequirementsRelease',
        name: 'requirementsRelease',
        component: () => import('./views/modules/businessRequirements/RequirementsRelease')
    },
    {

        path: '/businessRequirements/StatusQuery',
        name: 'statusQuery',
        component: () => import('./views/modules/businessRequirements/StatusQuery')
    },
    {
        path: '/businessRequirements/BusinessContent',
        name: 'businessContent',
        component: () => import('./views/modules/businessRequirements/BusinessContent')
    },
    {
        path: '/informationService/InformationService',
        name: 'informationService',
        component: () => import('./views/modules/informationService/InformationService'),
    },
    //活动管理相关的路径
    {
        path: '/ActivityManagement',
        name: 'activityManagement',
        component: () => import('./views/platform/activityManager/ActivityManagement')
    },
    {
        path: '/ActivityList',
        name: 'activityList',
        component: () => import('./views/platform/activityManager/ActivityList')
    },
    {
        path: '/Participants',
        name: 'participants',
        component: () => import('./views/platform/activityManager/Participants')
    },
    {
        path: '/questionnaire',
        name: 'questionnaire',
        component: () => import('./views/platform/questionnaire/questionnaire')
    },
    //消息推送相关路径
    {
        path: '/SendMessage',
        name: 'sendMessage',
        component: () => import('./views/platform/messagePush/SendMessage')
    },
    {
        path: '/messageTemplate',
        name: 'messageTemplate',
        component: () => import('./views/platform/questionnaire/messageTemplate')
    },
    {
        path: '/MessageList',
        name: 'messageList',
        component: () => import('./views/platform/messagePush/MessageList')
    },
    {
        path: '/NewsDetails',
        name: 'newsDetails',
        component: () => import('./views/platform/messagePush/NewsDetails')
    },
    {
        path: '/Drafts',
        name: 'drafts',
        component: () => import('./views/platform/messagePush/Drafts')
    },
    {
        path: '/RecruitUserList',
        component: () => import('./views/modules/recruit/RecruitUserList')
    }
    ,
    //智库管理相关路径
    {
        path: '/ProblemAllocation',
        name: 'problemAllocation',
        component: () => import('./views/platform/thinkTankManagement/ProblemAllocation')
    },
    {
        path: '/ExpertManagement',
        name: 'expertManagement',
        component: () => import('./views/platform/thinkTankManagement/ExpertManagement')
    },
    {
        path: '/AssistExtension',
        name: 'assistExtension',
        component: () => import('./views/platform/thinkTankManagement/AssistExtension')
    },
    {
        path: '/ResumeExamine',
        name: 'resumeExamine',
        component: () => import('./views/platform/thinkTankManagement/ResumeExamine')
    },
    {
        path: '/ResumeDetails',
        name: 'resumeDetails',
        component: () => import('./views/platform/thinkTankManagement/ResumeDetails')
    },
    {
        path: '/AddExperts',
        name: 'addExperts',
        component: () => import('./views/platform/thinkTankManagement/AddExperts')
    },
    {
        path: '/InformationEditor',
        name: 'informationEditor',
        component: () => import('./views/platform/thinkTankManagement/InformationEditor')
    },
    {
        path: '/PolicyInterpretation',
        name: 'policyInterpretation',
        component: () => import('./views/platform/thinkTankManagement/PolicyInterpretation')
    },
    {
        path: '/expertDiagram',
        component: () => import('./views/platform/dataDiagram/expertDiagram')
    },
    {
        path: '/eventType',
        name: 'eventType',
        component: () => import('./views/platform/dataDiagram/eventType')
    },
    {
        path: '/recruitData',
        name: 'recruitData',
        component: () => import('./views/platform/dataDiagram/recruitData')
    },
    {
        path: '/demandsData',
        name: 'demandsData',
        component: () => import('./views/platform/dataDiagram/demandsData')
    },
    {
        path: '/expertArticle',
        name: 'expertArticle',
        component: () => import('./views/platform/thinkTankManagement/expertArticle')
    },
    {
        path: '/expertResume',
        name: 'expertResume',
        component: () => import('./views/platform/thinkTankManagement/expertResume')
    },
    {
        path: '/funds',
        name: 'funds',
        component: () => import('./views/modules/funds/funds')
    },
    {
        path: '/Preview',
        name: 'preview',
        component: () => import('./views/platform/thinkTankManagement/Preview')
    },
//    企业会员列表相关路径
    {
        path: '/EnterpriseList',
        name: 'enterpriseList',
        component: () => import('./views/platform/memberManagement/EnterpriseList')
    },
    {
        path: '/EnterpriseDetailsEdit',
        name: 'enterpriseDetailsEdit',
        component: () => import('./views/platform/memberManagement/EnterpriseDetailsEdit')
    },
    {
        path: '/EnterpriseNeedAcceptance',
        name: 'enterpriseNeedAcceptance',
        component: () => import('./views/platform/memberManagement/EnterpriseNeedAcceptance')
    },
    {
        path: '/DemandSeminar',
        name: 'demandSeminar',
        component: () => import('./views/platform/memberManagement/DemandSeminar')
    },
    {
        path: '/questionDetails',
        component: () => import('./views/platform/thinkTankManagement/questionDetails')
    },
    {
        path: '/ActivityConfirmation',
        name: 'activityConfirmation',
        component: () => import('./views/platform/memberManagement/ActivityConfirmation')
    },
    //宣传内容管理
    {
        path: '/BannerGraph',
        name: 'bannerGraph',
        component: () => import('./views/platform/publicityContentManagement/BannerGraph')
    },
    {
        path: '/PropagandaArticles',
        name: 'propagandaArticles',
        component: () => import('./views/platform/publicityContentManagement/PropagandaArticles')
    },
    {
        path: '/ArticleManagement',
        name: 'articleManagement',
        component: () => import('./views/platform/publicityContentManagement/ArticleManagement')
    },
    {
        path: '/AddRecruitList',
        name: 'addRecruitList',
        component: () => import('./views/modules/recruit/AddRecruitList')
    },
    {
        path: '/CompaniesInformation',
        name: 'companiesInformation',
        component: () => import('./views/modules/CompaniesInformation')

    },
    {
        path: '/hotTopList',
        name: 'hotTopList',
        component: () => import('./views/platform/hotTop/HotTopList')
    },
    {
        path: '/DataDictionaries',
        name: 'dataDictionaries',
        component: () => import('./views/platform/DataDictionary/DataDictionaries')
    },
    {
        path: '/publicUser',
        name: 'publicUser',
        component: () => import('./views/modules/publicUser/PublicList')
    },
    {
        path: '/userInformation',
        name: 'userInformation',
        component: () => import('./views/platform/UserInformation')
    },
    {
        path: '/dataDiagram',
        name: 'dataDiagram',
        component: () => import('./views/platform/dataDiagram/dataDiagram')
    },
    {
        path: '/columnList',
        name: 'columnList',
        component: () => import('./views/platform/publicityContentManagement/ColumnList')
    },
    {
        path: '/videoList',
        name: 'videoList',
        component: () => import('./views/platform/trainingVideo/videoList')
    }
];
const routes = [
    {
        path: '/',
        name: 'background',
        component: () => import('./views/background'),
        children: childrenRouter
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
